/**
 * HomePage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import Layout from '../components/MainLayout';
import News from '../components/News';

const Content = styled.div``;

export default function Home(props) {
  return (
    <Layout>
      <Content>
        <News news={get(props, 'data.allMdx.edges', []) || []} hideButton />
      </Content>
    </Layout>
  );
};


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
